import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { LogoItem, Image, Name } from './logos-module.styles'

const Logo = ({ height, url, isCurrent, name, showName, width }) => {
  return (
    <LogoItem isActive={isCurrent} logoWidth={width}>
      <Image height={height} alt={name} src={url} />
      {showName && <Name>{name}</Name>}
    </LogoItem>
  )
}

Logo.propTypes = {
  height: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  showName: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
}

Logo.displayName = 'Logo'

export default memo(Logo)
