import { useCallback, useState } from 'react'

export function useCarousel({ totalPages, isInfinite }) {
  const [currentIndex, setCurrentIndex] = useState(0)

  const isFirstIndex = currentIndex === 0
  const isLastIndex = currentIndex === totalPages - 1

  const goToIndex = useCallback(index => {
    setCurrentIndex(index)
  }, [])

  const goToNextIndex = useCallback(() => {
    let nextIndex

    if (isInfinite && isLastIndex) {
      nextIndex = 0
    } else if (isLastIndex) {
      nextIndex = currentIndex
    } else {
      nextIndex = currentIndex + 1
    }

    goToIndex(nextIndex)
  }, [currentIndex, goToIndex, isInfinite, isLastIndex])

  const goToPrevIndex = useCallback(() => {
    let prevIndex

    if (isInfinite && isFirstIndex) {
      prevIndex = totalPages - 1
    } else if (isFirstIndex) {
      prevIndex = currentIndex
    } else {
      prevIndex = currentIndex - 1
    }

    goToIndex(prevIndex)
  }, [currentIndex, goToIndex, isFirstIndex, isInfinite, totalPages])

  return {
    currentIndex,
    goToIndex,
    goToNextIndex,
    goToPrevIndex,
    isFirstIndex,
    isInfinite,
    isLastIndex,
    totalPages,
  }
}
