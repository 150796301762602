import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { CarouselWrapper } from './carousel-type-fade.styles'
import { Item } from './item'

export const CarouselTypeFade = ({ children, currentIndex }) => {
  const [wrapperHeight, setWrapperHeight] = useState(0)

  return (
    <CarouselWrapper height={wrapperHeight}>
      {React.Children.toArray(children).map((child, index) => (
        <Item
          child={child}
          currentIndex={currentIndex}
          index={index}
          key={`carousel-type-fade-item-${index}`}
          updateWrapperHeight={setWrapperHeight}
        />
      ))}
    </CarouselWrapper>
  )
}

CarouselTypeFade.propTypes = {
  children: PropTypes.node.isRequired,
  currentIndex: PropTypes.number.isRequired,
  transitions: PropTypes.object,
}

CarouselTypeFade.defaultProps = {
  transitions: {},
}
