import { ALIGNMENT } from 'constants/alignment'
import mediaQueries from 'util/mediaqueries'

import styled from 'styled-components'
import Markdown from 'components/markdown'

import { Headline, Text } from '../typography'

export const Wrapper = styled.div`
  text-align: ${({ alignment }) =>
    alignment === ALIGNMENT.LEADING ? 'start' : 'center'};
`

export const Title = styled(Headline)`
  margin: 0;
`

export const Description = styled(Text)`
  margin: 16px 0 0;
  ${mediaQueries.sm`
    margin: 24px 0 0;
  `};
`

export const StyledMarkdown = styled(Markdown)`
  color: ${({ color }) => (color ? color : 'inherit')};
  a {
    text-decoration: underline;
  }
`
