export const LAYOUT_TYPE = {
  integrations: 'integrations',
  partners: 'partners',
  awards: 'awards',
  compact: 'compact',
}

const LAYOUTS = {
  [LAYOUT_TYPE.integrations]: { height: 56, margin: 55, width: 105 },
  [LAYOUT_TYPE.partners]: { height: 35, margin: -2, width: 168 },
  [LAYOUT_TYPE.awards]: { height: 125, margin: 55, width: 125 },
  [LAYOUT_TYPE.compact]: { height: 25, width: 168 },
}

export const getLayoutByType = type =>
  LAYOUTS[type] || LAYOUTS[LAYOUT_TYPE.integrations]
