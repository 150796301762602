export const trackPage = async ({ tracker, page, name, options = {} }) => {
  await tracker.trackPage(page, name, {
    diffTollerancePercent: 1,
    screenshotOptions: {
      fullPage: true,
      animations: 'disabled',
      ...options?.screenshotOptions,
    },
    ...options,
  })
}

export const isVRT = Boolean(process.env.VRT)
