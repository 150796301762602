import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

const Markdown = ({ children, inline, ...rest }) => {
  const components = {}

  if (!children) return null

  if (inline) {
    components.p = ({ node, ...props }) => <React.Fragment {...props} />
  }

  return (
    <ReactMarkdown components={components} {...rest}>
      {children}
    </ReactMarkdown>
  )
}

Markdown.displayName = 'Markdown'

Markdown.propTypes = {
  children: PropTypes.any,
  inline: PropTypes.bool,
}

Markdown.defaultProps = {
  children: null,
  inline: false,
}

export default Markdown
