import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { isComponent } from '../../util/is-component'

import { CarouselTypeFade } from './carousel-type-fade'
import { CarouselTypeSlide } from './carousel-type-slide'
import { TYPES } from './constants'

export const CarouselContext = React.createContext({})

export const Carousel = ({ children, hook, type, ...rest }) => {
  const carouselProps = {
    ...hook,
    ...rest,
  }

  const items = React.Children.toArray(children).map((child, index) => {
    const props = isComponent(child)
      ? {
          ...hook,
          index,
          isCurrent: hook.currentIndex === index,
        }
      : null

    return React.cloneElement(child, props)
  })

  const CarouselType = useMemo(() => {
    return type === TYPES.FADE ? CarouselTypeFade : CarouselTypeSlide
  }, [type])

  return (
    <CarouselContext.Provider value={hook}>
      <CarouselType {...carouselProps}>{items}</CarouselType>
    </CarouselContext.Provider>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  hook: PropTypes.object.isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)),
}

Carousel.defaultProps = {
  type: TYPES.SLIDE,
}

Carousel.displayName = 'Carousel'
