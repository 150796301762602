import React, { useRef, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import { isComponent } from '../../../util/is-component'

import {
  CarouselSled,
  CarouselSledContainer,
  CarouselItem,
} from './carousel-type-slide.styles'

const preventBubblingToParent = event => event.stopPropagation()
const triplicateForInfiniteCarousel = children =>
  children.concat(children).concat(children)

export function CarouselTypeSlide(props) {
  const { children, currentIndex, gap, goToIndex, isInfinite, totalPages } =
    props

  const sledContainerRef = useRef(null)
  const touchStartedAt = useRef(0)
  const isRewindingRef = useRef(false)
  const touchMovedByPercentage = useRef(0)
  const slides = useMemo(
    () => (!isInfinite ? children : triplicateForInfiniteCarousel(children)),
    [children, isInfinite]
  )
  const totalSlides = slides.length

  useEffect(() => {
    if (isInfinite) {
      goToIndex(totalPages)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isRewindingRef.current) {
      sledContainerRef.current.style.transition = 'none'
      const value = -(currentIndex / totalSlides) * 100
      sledContainerRef.current.style.transform = `translateX(${value}%)`
      setTimeout(() => {
        sledContainerRef.current.style.transition = null
      }, 10)

      isRewindingRef.current = false
    } else {
      const value = -(currentIndex / totalSlides) * 100
      sledContainerRef.current.style.transform = `translateX(${value}%)`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex])

  const onTouchStart = useCallback(event => {
    touchStartedAt.current = event.touches[0].clientX
  }, [])

  const onTouchEnd = useCallback(() => {
    sledContainerRef.current.style.transition = null

    if (touchMovedByPercentage.current > 0) {
      if (currentIndex < totalSlides - 1) {
        goToIndex(currentIndex + 1)
      } else {
        const nextStaticPercentage = (currentIndex / totalSlides) * 100
        sledContainerRef.current.style.transform = `translateX(${-nextStaticPercentage}%)`
      }
    }

    if (touchMovedByPercentage.current < 0) {
      if (currentIndex !== 0) {
        goToIndex(currentIndex - 1)
      } else {
        const nextStaticPercentage = (currentIndex / totalSlides) * 100
        sledContainerRef.current.style.transform = `translateX(${-nextStaticPercentage}%)`
      }
    }
  }, [currentIndex, goToIndex, totalSlides])

  const onCarouselTransitionEnd = useCallback(() => {
    if (isInfinite) {
      if (currentIndex > totalPages * 2) {
        isRewindingRef.current = true
        goToIndex(currentIndex - totalPages)
      }
      if (currentIndex < totalPages) {
        isRewindingRef.current = true
        goToIndex(currentIndex + totalPages)
      }
    }
  }, [currentIndex, goToIndex, isInfinite, totalPages])

  const onTouchMove = useCallback(
    event => {
      const currentX = event.touches[0].clientX
      const containerWidth = sledContainerRef.current.offsetWidth
      const movedByPixels = touchStartedAt.current - currentX
      const currentStaticPercentage = (currentIndex / totalSlides) * 100
      const movedByPercentage = (movedByPixels / containerWidth) * 100
      const absoluteCurrentPercentage =
        currentStaticPercentage + movedByPercentage

      touchMovedByPercentage.current = movedByPercentage
      sledContainerRef.current.style.transform = `translateX(${-absoluteCurrentPercentage}%)`
      sledContainerRef.current.style.transition = `none`
    },
    [currentIndex, totalSlides]
  )

  return (
    <CarouselSled
      onTouchMove={onTouchMove}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      <CarouselSledContainer
        gap={gap}
        ref={sledContainerRef}
        totalPages={slides.length}
        onTransitionEnd={onCarouselTransitionEnd}
      >
        {slides.map((slide, index) => {
          const pageProps = isComponent(slide)
            ? {
                ...slide.props,
                index,
                isCurrent: currentIndex % totalPages === index % totalPages,
              }
            : null

          return (
            <CarouselItem
              key={`slide-${index}`}
              onTransitionEnd={preventBubblingToParent}
            >
              {React.cloneElement(slide, pageProps)}
            </CarouselItem>
          )
        })}
      </CarouselSledContainer>
    </CarouselSled>
  )
}

CarouselTypeSlide.propTypes = {
  children: PropTypes.node.isRequired,
  currentIndex: PropTypes.number.isRequired,
  gap: PropTypes.string,
  goToIndex: PropTypes.func.isRequired,
  isInfinite: PropTypes.bool,
  totalPages: PropTypes.number.isRequired,
  touch: PropTypes.bool,
  transitions: PropTypes.object,
}

CarouselTypeSlide.defaultProps = {
  gap: '0',
  isInfinite: false,
  touch: true,
  transitions: {},
}

CarouselTypeSlide.displayName = 'CarouselTypeSlide'
