import { ALIGNMENT } from 'constants/alignment'

import React from 'react'
import PropTypes from 'prop-types'
import { DEFAULT_SIZE, SIZES } from 'components/typography/headline'

import {
  Wrapper,
  Title,
  Description,
  StyledMarkdown,
} from './headline-and-text.styles'

const HeadlineAndText = props => {
  const {
    title,
    titleSize,
    titleProps,
    description,
    alignment,
    descriptionProps,
    ...rest
  } = props

  const textColor = rest?.style?.color
  const titleStyle = {}
  if (textColor) {
    titleStyle.color = textColor
  }

  return (
    <Wrapper alignment={alignment} {...rest}>
      <Title
        id={encodeURIComponent(title)}
        size={titleSize}
        {...titleProps}
        style={titleStyle}
      >
        {title}
      </Title>
      {!!description && (
        <Description as={'div'} {...descriptionProps}>
          <StyledMarkdown color={textColor}>{description}</StyledMarkdown>
        </Description>
      )}
    </Wrapper>
  )
}

HeadlineAndText.displayName = 'HeadlineAndText'

HeadlineAndText.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleSize: PropTypes.oneOf(Object.values(SIZES)),
  alignment: PropTypes.oneOf(Object.values(ALIGNMENT)),
  descriptionProps: PropTypes.object,
  titleProps: PropTypes.object,
}

HeadlineAndText.defaultProps = {
  description: null,
  titleSize: DEFAULT_SIZE,
  alignment: ALIGNMENT.DEFAULT,
  descriptionProps: {},
  titleProps: null,
}

export default HeadlineAndText
