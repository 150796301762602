import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMeasure } from 'react-use'

import { Wrapper } from './item.styles'

export const Item = ({
  child,
  index,
  currentIndex,
  updateWrapperHeight,
  ...props
}) => {
  const [ref, { height: itemHeight }] = useMeasure()

  const isCurrentIndex = index === currentIndex

  useEffect(() => {
    isCurrentIndex && updateWrapperHeight(itemHeight)
  }, [isCurrentIndex, itemHeight, updateWrapperHeight])
  return (
    <Wrapper isActive={isCurrentIndex} ref={ref} {...props}>
      {child}
    </Wrapper>
  )
}

Item.propTypes = {
  child: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  updateWrapperHeight: PropTypes.func.isRequired,
}
