import { BREAKPOINTS } from 'constants/design'
import {
  IMAGE_LOADING,
  IMAGE_DECODING,
  IMAGE_FETCHPRIORITY,
} from 'constants/image'
import { isVRT } from 'util/vrt'

import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useInterval, useMedia } from 'react-use'
import { Carousel, useCarousel } from 'components/copenhagen-carousel'

import {
  Container,
  Row,
  Image,
  LogoContainer,
  Name,
  CarouselContainer,
  StyledHeadlineAndText,
} from './logos-module.styles'
import { getLayoutByType, LAYOUT_TYPE } from './constants'
import Logo from './logo'

const defaultLayoutType = LAYOUT_TYPE.integrations
const USE_MEDIA_DEFAULT_STATE = false
/**
 * @deprecated
 * This module has been migrated to the Public Main Site
 * @see: https://github.com/Typeform/public-main-site/blob/main/components/modules/logos-module/logos-module.tsx
 */
const LogosModule = ({ title, type, logos, ...rest }) => {
  const carousel = useCarousel({
    isInfinite: true,
    totalPages: logos.length,
  })

  const isMobileWidth = useMedia(
    `(max-width: ${BREAKPOINTS.sm - 1}px)`,
    USE_MEDIA_DEFAULT_STATE
  )
  const isMobile = typeof window !== 'undefined' && isMobileWidth

  const showName = type === LAYOUT_TYPE.integrations

  const goToNextLogo = useCallback(() => {
    carousel.goToNextIndex()
  }, [carousel])

  useInterval(
    () => {
      goToNextLogo()
    },
    isVRT ? null : 1500
  )

  const { width, height } = getLayoutByType(type)

  return (
    <Container as='section' {...rest}>
      {title && (
        <StyledHeadlineAndText
          title={title}
          titleSize={type === LAYOUT_TYPE.compact ? 1 : 2}
        />
      )}
      {isMobile && (
        <CarouselContainer>
          <Carousel gap='64px' hook={carousel} touch={false}>
            {logos.map(({ name, url, image }, index) => (
              <Logo
                height={height}
                index={index}
                key={`logo-${index}`}
                name={name}
                showName={showName}
                url={url || image.url}
                width={width}
              />
            ))}
          </Carousel>
        </CarouselContainer>
      )}
      {!isMobile && (
        <Row>
          {logos.map(({ name, url, image }, index) => (
            <LogoContainer key={`logo-${index}`}>
              <Image height={height} alt={name} src={url || image.url} />
              {showName && <Name>{name}</Name>}
            </LogoContainer>
          ))}
        </Row>
      )}
    </Container>
  )
}

LogosModule.displayName = 'LogosModule'

LogosModule.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.values(LAYOUT_TYPE)),
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
      loading: PropTypes.oneOf(Object.values(IMAGE_LOADING)),
      decoding: PropTypes.oneOf(Object.values(IMAGE_DECODING)),
      fetchpriority: PropTypes.oneOf(Object.values(IMAGE_FETCHPRIORITY)),
    })
  ),
}

LogosModule.defaultProps = {
  title: '',
  logos: [],
  type: defaultLayoutType,
}

export default memo(LogosModule)
